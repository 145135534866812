<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
      <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
        Employee list ({{totalLeadCount}})
      </div>
      </div>
      <div class="mt-8 flex justify-between flex-wrap">
        <div class="flex flex-wrap mb-4">
          <div class="" v-for="(status, index) in statusList" :key="index" >
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactive(status)" :selected="status.selected" :btnText="status.statusName" :lastInlist="statusList.length === index + 1"/>     
          </div>
        </div>
        <div>
          <Button  class=" h-10 w-full" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Employee'" @buttonAction="addNewEmp()"/>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="leadList.length > 0">
          <div v-for="(data, index) in leadList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.userDetailId)">
            <div class="">
              <!-- <p class="  text-text1 font-bold">{{}}</p> -->
              <div class="flex justify-between items-center">
                <p class="text-text2 heading-4 font-semibold">{{data.firstName}} {{data.lastName}}</p>
                <div :style="`background: ${data.displayColor}`" class=" rounded-full h-4 w-4 border"></div>
              </div>
              <div class="flex justify-between items-center">
                <p class="text-gray4 heading-5" v-if="data.userName !== ''">Username: {{data.userName}}</p>
                <p class="text-gray4 heading-5" v-if="data.isAdmin && !data.isOwner">Admin</p>
                <p class="text-gray4 heading-5" v-if="data.isOwner">Owner</p>
              </div>
              <p class="text-gray4 heading-6" v-if="data.emailAddress !== ''">{{data.emailAddress}}</p>
              <div class="flex justify-between items-center">
                <p class="text-gray4 heading-6"><span v-if="data.cellNumber !== ''">{{data.cellNumberPrefix}} {{data.cellNumber | phone}}</span></p>
              </div>
              </div>
          </div>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div>
        <div class=" mt-4">
          <Pagination
            :totalDataCount="totalLeadCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getLeadDataFromPagination"/>
        </div>
      </div>
     </div>
     <!-- <div v-if="addnewEmp" class="popup_overlay px-4">
      <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
        <div class="relative">
          <div class="">
            <div class="bg-primary flex justify-between p-3">
              <div class=" font-medium text-white heading-3">
                  Add New Employee
              </div>
            </div>
            <div class="p-4">
              <OrganizationQR v-if="addnewEmp" :hideDetail="'addEmp'"  />
            </div>
          </div>
            <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
              <div class="text-rigth flex gap-2 mb-3">
                  <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'close'" @buttonAction="addnewEmp = false"/>
              </div>
              </div>
          </div>
      </div>
    </div> -->
   </div>
</template>
<script>
import Pagination from '@/View/components/pagination.vue'
import MyJobApp from '@/api/MyJobApp.js'
import deboucneMixin from '@/mixins/debounce.js'
import NoRecords from '@/View/components/noRecords.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import Button from '@/View/components/globalButton.vue'
// import OrganizationQR from '../../login/components.vue/organizationQR.vue'
export default {
  name: "lead",
  components: {
    Pagination,
    StatusBtn,
    NoRecords,
    Button,
    // OrganizationQR,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      statusList: [
        {statusName: 'Active', value: 'active', selected: true},
        {statusName: 'Inactive', value: 'inactive', selected: false}
      ],
      leadList: [],
      searchForLead: '',
      getLeadListDebounce: null,
      resetPaginationValues: false,
      addnewEmp: false,
      totalLeadCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        statusList: true
      },
    };
  },
  created() {},
  mounted() {
    document.body.style = 'overflow: auto;'
    this.getLeadList()
     this.getLeadListDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
  },
  beforeDestroy() {
    // this.$root.$off('addCustomerHandler')
  },
  methods: {
    addNewCust () {
      this.addLead= true
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getLeadList(true)
    },
    openDetail (id) {
      this.$router.push({name: 'SettingEmployeesDetail', params: {empId: id}, query: {showDelele: false}})
    },
    searchCall (data) {
      this.searchForLead = data
      this.getLeadListDebounce()
    },
    addNewEmp () {
      this.$router.push({name: 'SettingEmployeesAdd', params: {empId: 0}})
    },
    toggleActiveAndInactive (data) {
      this.filterObject.statusList = data.value
      for (let index = 0; index < this.statusList.length; index++) {
        if (this.statusList[index].statusName === data.statusName) {
          this.statusList[index].selected = true
        } else {
          this.statusList[index].selected = false
        }
      }
      this.resetPaginationValues = true
      this.getLeadList()
    },
    getLeadList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getSettingEmployeeList(
          this.filterObject.statusList,
          response => {
            this.totalLeadCount = response.Data.count
            this.leadList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  }
};
</script>
<style scoped>
.lead_name_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>